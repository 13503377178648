<template>
  <div class="link-expired">
    <div class="link-expired__banner">
      <AppIcon
        name="clock-thin"
        opacity="0.3"
        is-img-tag
        size="40px"
      />
      <AppText
        size="18px"
        class="font-medium"
        mt="23px"
      >
        {{ t('common.linkExpired') }}
      </AppText>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkExpired',
};
</script>

<style lang="scss" scoped>
.link-expired {
  height: 100%;
  width: 100%;

  &__banner {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
